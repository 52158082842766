<template>
  <div>
    <div v-show="showData">
      <v-app-bar dark color="#19647E">
        <v-icon large class="mr-3">done_all</v-icon>
        <v-toolbar-title>Completes</v-toolbar-title>
        <v-toolbar-title class="ml-10">{{ UPName }}</v-toolbar-title>
      </v-app-bar>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-flex xs12 md6>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-flex>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="10"
        class="elevation-1"
      ></v-data-table>

      <v-btn @click="checkIncompletes()" color="#19647E" dark class="mt-5">Check Incompletes</v-btn>
    </div>

    <div v-show="!showData">
      <v-app-bar dark color="#19647E">
        <v-icon large class="mr-3">pending_actions</v-icon>
        <v-toolbar-title>Incompletes :</v-toolbar-title>
        <v-toolbar-title class="ml-10">{{ UPName }}</v-toolbar-title>
      </v-app-bar>

      <v-card-title>
        <v-spacer></v-spacer>
        <v-flex xs12 md6>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-flex>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="incompletes"
        :search="search"
        :items-per-page="10"
        class="elevation-1"
      ></v-data-table>

      <v-btn @click="goBack()" color="#19647E" dark class="mt-5">Back</v-btn>
    </div>
  </div>
</template>

<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      data: [],
      incompletes: [],
      showData: true,
      search: "",
      UPName: this.$route.query.cardName,
      headers: [
        { text: "Name", value: "name" },
        { text: "Country Code", value: "countryCode" },
        { text: "Mobile Number", value: "mobileNumber" },
        { text: "Email", value: "email" },
        { text: "Date of installation", value: "created_at" },
        { text: "Submission At", value: "submissionAt" },
      ],
    };
  },
  mounted() {
    this.getCardData();
  },

  methods: {
    getCardData() {
      this.$setLoader();

      dataServiceVD.getCardData({
        profilingId: this.$route.query.profilingId,
        projectCodes: JSON.parse(this.$route.query.projectCodes),
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate
      }).then((res) => {
        this.data = res.data;
        this.$disableLoader();
      });
    },

    checkIncompletes() {
      this.$setLoader();
      this.showData = false;

      dataServiceVD.checkIncompletes({
        profilingId: this.$route.query.profilingId,
        projectCodes: JSON.parse(this.$route.query.projectCodes),
      }).then((res) => {
        this.incompletes = res.data;
        this.$disableLoader();
      });
    },

    goBack() {
      this.showData = true;
    },
  },
};
</script>